<template>
    <div style="height: 40vh">
        <v-row>
            <v-col>
                <div class="d-flex ml-3 p-2">
                    <div class="search">
                        <input type="text" class="searchTerm" v-model="searchValue" :placeholder="$t('pages.crm.customers.sections.customers.table_header.search_text')">
                        <button type="submit" @click="searchConfirm()" class="searchButton">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div>
                    <ve-table style="width:100%" :columns="columns" :table-data="tableData" row-key-field-name="rowKey"/>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';

    export default {

        props : ['select_groups'],

        data() {
            return {
                all_data: [],
                tableData: [],
                searchValue: "",
                columns: [
                    {
                        field: "",
                        key: "a",
                        title: "n°",
                        width: 50,
                        align: "center",
                        renderBodyCell: ({ row, column, rowIndex }, h) => {
                            return ++rowIndex;
                        },
                    },
                    {
                        field: "name",
                        key: "b",
                        title: this.$t('pages.crm.customers.sections.customers.table_header.name'),
                        align: "left",

                    },
                    {
                        field: "function",
                        key: "c",
                        title: this.$t('pages.crm.customers.sections.customers.table_header.types'),
                        align: "left",
                    },
                    {
                        field: "ville",
                        key: "d",
                        title: this.$t('pages.crm.customers.sections.customers.table_header.towns'),
                        align: "center",
                    },
                    { 
                        field: "action", 
                        key: "e", 
                        title: "Action",
                        width: "",
                        align: "right",
                        renderBodyCell: ({ row, column, rowIndex }, h) => {
                            return (
                                <span>
                                    <el-button type="primary" icon="el-icon-plus"  on-click={() =>this.loadData(row)} size='mini' class="p-2"></el-button>
                                    <el-button type="danger" icon="el-icon-delete" on-click={() =>this.loadData(row)} size='mini' class="p-2"></el-button>
                                    
                                    &nbsp;
                                </span>
                            );
                        },
                    },
                ],   


            }
        },

        created(){
            this.loadData();
        },

        methods: {
            async loadData()
            {
                NProgress.start();

                try {
                    const response = await axios.get('v1/xperf/crm/get/customers');
                    this.all_data = response.data;
                    this.tableData = this.all_data.slice(0);
                    this.loading = false;
                } 
                catch (error) {
                    this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }

                NProgress.done();
            },

                        // search
            search() {
                this.loading = true;
                const searchValue = this.searchValue;
                this.tableData = this.all_data.filter(
                    (x) =>
                        !searchValue.length ||
                        x.name.toLowerCase().includes(searchValue.toLowerCase()),
                );

                this.loading = false;
            },

            // search cancel
            searchConfirm(closeFn) {
                this.search();
                // closeFn();
            },
        },
    }
</script>