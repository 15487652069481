<template>
    <div>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="12" >
                    <v-text-field class="purple-input" v-model="form.title" :label="$t('pages.crm.customers.sections.groups.add.name')"/>
                </v-col>
                <v-col cols="12" md="12" >
                    <!-- <v-text-field class="purple-input" v-model="form.description" :label="$t('pages.crm.customers.sections.groups.add.desc')"/> -->
                    <el-input :placeholder="$t('pages.crm.customers.sections.groups.add.desc')" type="textarea" :autosize="{ minRows: 3, maxRows: 6}" maxlength="190" v-model="form.description" style="border-radius:0px"></el-input>
                </v-col>
                <v-col cols="12" md="8" class="text-right" >
                    <el-button type="primary" @click="handleSave()" style="border-radius: 0px"> <i class="fa fa-save mr-2"></i>{{$t('global_button_save')}}</el-button>
                </v-col>
                
            </v-row>
        </v-card-text>
    </div>
</template>
<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';
    import moment from 'moment';

    export default {

        components: {
        },

        props: ['form', 'type','loadData'],

        data() {
            return {
                search: '',
                loading: true,

                sexes: [{
                        value: 'M',
                        label:  this.$t('pages.crm.customers.sections.customers.add.label_sexe.male')
                    }, {
                        value: 'F',
                        label:  this.$t('pages.crm.customers.sections.customers.add.label_sexe.feminine')
                    }
                ],

                villes: [{
                    value: 'doua',
                    label:  this.$t('pages.crm.customers.sections.customers.add.label_town.douala')
                    }, {
                    value: 'yaou',
                    label:  this.$t('pages.crm.customers.sections.customers.add.label_town.yaounde')
                    }, {
                    value: 'bafo',
                    label:  this.$t('pages.crm.customers.sections.customers.add.label_town.bafoussam')
                    }
                ],
                type_customers: [
                    {
                        value: 'Pressing',
                        label: this.$t('pages.crm.customers.sections.customers.add.label_type.pressing')
                    }, {
                        value: 'Hotel',
                        label: this.$t('pages.crm.customers.sections.customers.add.label_type.hotel')
                    }, {
                        value: 'particular',
                        label: this.$t('pages.crm.customers.sections.customers.add.label_type.particular')
                    }, {
                        value: 'unknow',
                        label: this.$t('pages.crm.customers.sections.customers.add.label_type.unknow')
                    }
                ],
            }
        },

        created(){
        },
        methods: {

            handleEdit(index, row) {
                this.dialog = true;
                this.form = row;
            },

            handleSave() {
                if (this.type == 1) {
                    this.save();
                    
                } else if(this.type == 2) {
                    this.update();
                }
            },

            async save() {
                
                NProgress.start();

                try {

                    this.form.is_active = 1;
                    this.form.type_id = 1;

                    console.log(this.form);
                    const response = await axios.post('v1/xperf/crm/store/groups', this.form);
                    this.loadData();
                    this.form = {};

                    this.$swal(
                        ""+this.$t('global_alert_succes_title')+"",
                        ""+this.$t('global_alert_succes_text')+"",
                        'success'
                    );
                } 
                catch (error) {
                    this.$swal(
                        ""+this.$t('global_alert_echec_title')+"",
                        ""+this.$t('global_alert_echec_text')+"",
                        'error'
                    );
                }

                NProgress.done();

            },

            async update() {
                
                NProgress.start();

                try {

                    this.form.username = this.form.email;
                    const response = await axios.post('v1/xperf/crm/update/groups', this.form);
                    this.loadData();
                    this.$swal(
                        ""+this.$t('global_alert_succes_title')+"",
                        ""+this.$t('global_alert_succes_text')+"",
                        'success'
                    );
                } 
                catch (error) {
                    this.handleClose();
                     this.$swal(
                        ""+this.$t('global_alert_succes_title')+"",
                        ""+this.$t('global_alert_succes_text')+"",
                        'warning'
                    );
                }

                NProgress.done();

            },
        },
    }
</script>

<style>
    
    element.style {
        overflow:hiden;
    }
</style>